import React from 'react';
import Navbar from '../components/Navbar';
import Heroimage from '../components/Heroimage';

const Home = () => {
  return (
    <div>
      <Navbar/>
      <Heroimage />
      </div>
  );
};

export default Home;